<template>
    <Header :isShow="isShow" />
    <div class="wraper">
        <div class="content_box max_small_box" ref="header">
            <div class="content" v-if="detail">
                <div class="list" v-for="(item, index) in detail" :key="index">
                    <router-link :to="`/about/nationalInspection_detail/${item.id}/national_inspection`">
                        <div class="img">
                            <img :src="imgUrl + item.image" alt="">
                        </div>
                        <div class="text">
                            <p class="title">{{item.name}}</p>
                            <div class="desc" >{{item.desc}}</div>
                            <p class="more">查看更多</p>
                            <div class="line"></div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="pagination max_small_box" >
                <a-pagination v-model:current="page" :total="total_page" @change="handleChangePage" />
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import AboutTop from '../../components/about/AboutTop';


//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理函数
const detailEffect = (route, header) => {
    //详情
    const detail = ref()

     //页数
    const page = ref(1)

    //总页数
    const total_page = ref()

    //获取详情
    const getDetail = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
            page: page.value
        }
        await post('/about/get_about', postData).then((res) => {
            // console.log('国检中心简介', res)
            if(res.code == 1) {
                detail.value = res.data.data,
                total_page.value = res.data.total
            }
        })
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
        document.documentElement.scrollTop = document.body.scrollTop = header.value.offsetTop - 60;
    }

    watchEffect(() =>{
        getDetail(route.params.id, route.params.page_id)
    })

    return { detail, getDetail, page, total_page, handleChangePage }
}

export default {
    name: 'NationalInspection',
    components: { Header, Footer, FloatingFrame, AboutTop },
    setup() {
        const  route = useRoute()
        //获取滑块到顶部的距离
        const header = ref(null)
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理函数
        const { detail, getDetail, page, total_page, handleChangePage } = detailEffect(route, header)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        const { imgUrl }  = api


        return { imgUrl, isShow, header, detail, page, total_page, handleChangePage }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.content_box{
    margin-top: 60px;
}
.content{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
        display: block;
    }
    .list{
        width: 48%;
        margin-right: 4%;
        margin-bottom: 70px;
        cursor: pointer;
        &:nth-child(2n){
            margin-right: 0%;
        }
        &:hover{
            .text{
                .line{
                    width: 100%;
                }
            }
        }
        @media screen and (max-width: 1200px){
            width: 100%;
            display: block;
        }
        a{  
            width: 100%;
            display: flex;
            @media screen and (max-width: 1200px){
                width: 100%;
                display: block;
            }
        }
        .img{
            width: 48%;
            height: 200px;
            margin-right: 2%;
            @media screen and (max-width: 1200px){
                width: 100%;
                height: auto;
                margin-right: 0%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
        .text{
            width: 48%;
            margin-left: 2%;
            @media screen and (max-width: 1200px){
                width: 100%;
                margin-left: 0%;
            }
            .title{
                color: rgba(47, 80, 158, 100);
                font-size: 20px;
                font-weight: 600;
                margin-top: 12px;
                /* overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap; */
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
            .desc{
                color: $all-desc-color;
                font-size: 14px;
                margin-top: 20px;
                line-height: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            }
            .more{
                color: rgba(255, 255, 255, 100);
                font-size: 14px;
                padding: 10px 15px;
                background-color: #2F509E;
                margin-top: 20px;
                width: max-content;
                border-radius: 5px;
                @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            }
            .line{
                margin-top: 20px;
                width: 0;
                height: 2px;
                background-color: $content-backgroundcolor;
                transition: .8s all;
            }
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 80px;
    display: block;
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>